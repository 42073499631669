import React from 'react';
import { Redirect, Switch, Route, BrowserRouter as Router, NavLink } from 'react-router-dom';
import Loadable from 'react-loadable';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Helper from './library/Helper';
import Cookies from 'universal-cookie';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Skeleton from '@material-ui/lab/Skeleton';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import $ from 'jquery';

const cookie = new Cookies();
const Loading = () => (
    <div className="page-contents">
        <Grid container spacing={1} justify="space-between">
            <Grid item xs={4}><Skeleton /></Grid>
            <Grid item xs={8}><Skeleton /></Grid>
            <Grid item xs={12}><Skeleton /></Grid>
            <Grid item xs={4}><Skeleton /></Grid>
            <Grid item xs={8}><Skeleton /></Grid>
            <Grid item xs={12}><Skeleton /></Grid>
            <Grid item xs={4}><Skeleton /></Grid>
            <Grid item xs={8}><Skeleton /></Grid>            
            <Grid item xs={12}><Skeleton /></Grid>
        </Grid>
    </div>
);

const DashboardPage = Loadable({
    loader: () => import('./components/Dashboard'),
    loading: Loading
});

const LoginPage = Loadable({
    loader: () => import('./components/Login'),
    loading: Loading
});

const LogoutPage = Loadable({
    loader: () => import('./components/Logout'),
    loading: Loading
});

const PageNotFound = Loadable({
    loader: () => import('./components/404'),
    loading: Loading,
    delay: 100
});

const styles = {
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -18,
        marginRight: 10,
    },
    list: {
        width: 250,
    }
};

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render = { props =>
            Helper.isSessionExpired() === false ? <Component {...props} /> : <Redirect to="/login" />
        }
    />
);

const Page = (props) => {
    const menus = [];

    props.menu.map(function (row, i) {        
        return menus[row.menu_id] = Loadable({
            loader: () => import(`${row.component}`),
            loading: Loading
        });
    })

    return menus;
};

const SwitchRoute = (props) => {
    var page = Page(props);
    
    return (
        <Switch>
            <PrivateRoute exact path="/" component={DashboardPage} />
            {props.menu.map((row, i) => (
                <PrivateRoute key={i} exact path={row.url} component={page[row.menu_id]} />
            ))}            
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/logout" component={LogoutPage} />
            <Route component={PageNotFound} />
        </Switch>
    );
}

const MenuDrawer = (props) => {
    if (!Helper.isSessionExpired()) {
        return <IconButton className={props.classes.menuButton} color="inherit" aria-label="Menu" onClick={props.state.toggleDrawer(true)}><MenuIcon /></IconButton>;
    }

    return <></>;
}

const initialization = state => {
    state.setState({ isAjaxLoading: true, isOpen: true, message: 'Initializing...' });
    
    //Load Menu
    $.post(`${Helper.API}auth-menu`, { username: cookie.get('okp-user-name') }, function (res) {
        state.setState({ menuData: res.menu, isAjaxLoading: true, isOpen: false, message: '' });

        //Set shift config
        cookie.set('okp-conf-shift', res.shiftConf, { path: '/' });
        cookie.set('okp-conf-user', res.userConf, { path: '/' });        

        if (!cookie.get('okp-shift') && cookie.get('okp-is-logged')) {
            state.setState({ isOpenShiftDialog: true });
        } else {
            state.setState({
                isOpenShiftDialog: false,
                selectedShift: cookie.get('okp-shift')
            });
        }
    }, 'json').fail(function (xhr, status, error) {
        if (status === 'error') {
            var msg = `Error: Cannot connect to the server ${Helper.API}`;
            state.setState({ isOpen: true, message: msg });
            console.log(msg);
        }
    });
}

const AppTheme = {
    palette: {
        type: 'light',
        primary: { main: '#c20008' },
        secondary: { main: '#F6921E' }
    },
    typography: {
        useNextVariants: true
    }
};

const MenuApps = props => {
    if (props.isAjaxLoading) {
        return <Skeleton />;
    } else {
        if (props.menuData.length > 0) {
            return (
                props.menuData.map((row, i) =>
                    <NavLink key={i} exact activeClassName="active" className="no-underline" to={row.url}>
                        <ListItem button>
                            <ListItemText primary={row.name} />
                        </ListItem>
                    </NavLink>
                )
            );
        } else {
            return (
                <ListItem button>
                    <ListItemText primary={'[Not Authorized]'} style={{ color: 'red' }} />
                </ListItem>
            );
        }
    }
}

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openDrawer: false,
            menuData: [],
            //Shift Dialog
            isOpenShiftDialog: false,
            selectedShift: '',
            isAjaxLoading: false,
            //Snackbar
            isOpen: false,
            message: ''
        };
    }

    componentDidMount() {        
        initialization(this);   
        //getNotification(this);
    }

    toggleDrawer = (state) => () => {
        this.setState({ openDrawer: state });
    }    

    handleChangeShift = (event) => {
        this.setState({ selectedShift: event.target.value });
        $('#okp-notif-header').text('');
    }

    setShift = () => {
        let shift = this.state.selectedShift;
        if (!shift) {
            return;
        }

        cookie.set('okp-shift', shift, { path: '/' });

        this.setState({
            isOpenShiftDialog: false,
            selectedShift: shift
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Router>                
                <MuiThemeProvider theme={createMuiTheme(AppTheme)}>                    
                    <div className={classes.root}>                    
                        <AppBar position="fixed">
                            <Toolbar variant="dense">
                                <MenuDrawer classes={classes} state={this} />
                                <img src="nabati.png" alt="Nabati" style={{ width: '5%', height: '5%' }} />
                                <Typography variant="h6" color="inherit" style={{ marginLeft: 10, flex: 1 }}>OKP</Typography>
                                <Typography variant="h6" color="inherit" style={{ cursor: 'pointer' }} onClick={() => (this.setState({ isOpenShiftDialog: true }))}><span id="okp-notif-header" style={{ color: 'yellow', marginRight: '5px' }}></span>{this.state.selectedShift && cookie.get('okp-is-logged') && `SHIFT ${this.state.selectedShift}`}</Typography>
                                <input type="hidden" id="okp-selected-shift" value={this.state.selectedShift} />
                            </Toolbar>
                        </AppBar>           
                        <Drawer open={this.state.openDrawer} onClose={this.toggleDrawer(false)}>
                            <div
                                tabIndex={0}
                                role="button"
                                onClick={this.toggleDrawer(false)}
                                onKeyDown={this.toggleDrawer(false)}
                            >   
                                <List>
                                    <NavLink exact activeClassName="active" className="no-underline" to="/">
                                        <ListItem button>
                                            <ListItemText primary="Dashboard" />
                                        </ListItem>
                                    </NavLink>
                                </List>
                                <Divider />
                                <List className={classes.list}>
                                    <MenuApps menuData={this.state.menuData} />                                
                                </List>
                                <Divider />
                                <List>
                                    <NavLink exact activeClassName="active" className="no-underline" to="/logout">
                                        <ListItem button>                                
                                            <ListItemText primary="Logout" />
                                        </ListItem>
                                    </NavLink>
                                </List>
                            </div>
                        </Drawer>
                        <SwitchRoute menu={this.state.menuData} />
                    </div>                
                    <Dialog
                        open={this.state.isOpenShiftDialog}
                        aria-labelledby="okp-change-shift"
                    >
                        <DialogTitle id="okp-change-shift">Select Shift</DialogTitle>
                        <DialogContent>
                            <Select
                                value={this.state.selectedShift}
                                onChange={e => this.handleChangeShift(e)}
                            >
                                <MenuItem value={1}>Shift 1</MenuItem>
                                <MenuItem value={2}>Shift 2</MenuItem>
                                <MenuItem value={3}>Shift 3</MenuItem>
                            </Select>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.setShift} color="primary">Set</Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={this.state.isOpen}
                        autoHideDuration={2000}
                        message={this.state.message}
                    />
                </MuiThemeProvider>
            </Router>
        );
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
