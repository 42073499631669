import Cookies from 'universal-cookie';

const Helper = {
    API: process.env.REACT_APP_API,
    SAP: process.env.REACT_APP_SAP,
    SSO: process.env.REACT_APP_SSO,
    isSessionExpired: function () {
        const cookie = new Cookies();
        const sessionExpired = cookie.get('okp-user-expired');
        //const shift = cookie.get('okp-shift');

        if (!sessionExpired)
            return true;

        var expired = new Date(sessionExpired * 1000);
        var now = new Date();

        let status = now.getTime() > expired.getTime();

        if (status) {
            //cookie.remove('okp-shift');
            //cookie.remove('okp-is-logged');            
        }

        //return status;
        return false;
    },
    getCurrentDate: function () {
        const cookie = new Cookies();
        var selectedShift = cookie.get('okp-shift');
        var json = cookie.get('okp-conf-shift');
        var currentDate = new Date()

        if (selectedShift) {            
            if (typeof json[selectedShift] == undefined) {
                return currentDate
            }

            var conf =  json[selectedShift]
            var startTime = conf.start_time;
            var midTime1 = '23:59:59';
            var midTime2 = '00:00:00';
            var endTime = conf.end_time;

            var startDate = new Date(currentDate.getTime());
            startDate.setHours(startTime.split(":")[0]);
            startDate.setMinutes(startTime.split(":")[1]);
            startDate.setSeconds(startTime.split(":")[2]);

            var midDate1 = new Date(currentDate.getTime());
            midDate1.setHours(midTime1.split(":")[0]);
            midDate1.setMinutes(midTime1.split(":")[1]);
            midDate1.setSeconds(midTime1.split(":")[2]);

            var midDate2 = new Date(currentDate.getTime());
            midDate2.setHours(midTime2.split(":")[0]);
            midDate2.setMinutes(midTime2.split(":")[1]);
            midDate2.setSeconds(midTime2.split(":")[2]);

            var endDate = new Date(currentDate.getTime());
            endDate.setHours(endTime.split(":")[0]);
            endDate.setMinutes(endTime.split(":")[1]);
            endDate.setSeconds(endTime.split(":")[2]);

            var resultDate = currentDate;
            if (parseInt(selectedShift) === 3) {
                if (currentDate >= midDate2 && currentDate <= midDate1) {
                    resultDate = currentDate
                }

                if (currentDate >= midDate2 && currentDate <= endDate) {
                    resultDate = new Date(currentDate - (1 * 24 * 60 * 60 * 1000))
                }            
            }
            
            return resultDate
        }
            
        return currentDate
    },
	ajaxOptions: {
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    },
    ajaxOptionsFile: {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
};

export default Helper;
